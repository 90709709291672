import $ from 'jquery';


$(document).ready(function (){

    let submitButton = $('#submit_button');
    let token = $('meta[name="csrf-token"]').attr('content');
    submitButton.on('click',function (){
        axios.post('/authenticate',
            {
                token: token,
                email: $('#email').val(),
                password: $('#password').val()
            })
            .then( (response) => {
                console.log(response.data);
                // TODO store token somewhere for pure JS solution
                alert(response.data.message);
                if (response.data.user.role_id === 1) {
                    window.location = '/tickets'
                }else
                {
                    window.location = '/admin';
                }

            })
            .catch( (error) =>  {
               console.log(error.response);
                alert(error.response.data.message);
            });

    });

})
